<template>
  <div v-if="crumbs.length" class="breadcrumb_container">
    <span v-for="(crumb, index) in crumbs" :key="index">
      <a :href="crumb.link" v-html="crumb.text"></a>
      <template v-if="index != crumbs.length - 1"> / </template>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "BreadCrumb",
  data() {
    return {
      crumbs: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getBreadcrumbDetails", "getDynamicBreadcrumb", "getCanUpdateBreadcrumb"]),
  },
  methods: {
    ...mapMutations(["setCanUpdateBreadcrumb"]),
    updateDynamicBreadcrumb(val) {
      let crumb = [];
      val.forEach((element) => {
        let link;
        if ("route" in element) {
          link = element.route;
        } else if ("routeName" in element) {
          link = this.$router.resolve({ name: element.routeName }).fullPath;
        }
        crumb.push({
          text: element.text,
          link,
        });
      });

      this.crumbs = crumb;
    }
  },
  watch: {
    $route(to) {
      var currentBreadcrumbState = this.getCanUpdateBreadcrumb
      if (currentBreadcrumbState == true) {
        this.setCanUpdateBreadcrumb(false)
        return;
      }
      let val = this.getDynamicBreadcrumb;
      if (!val.length) {
        if (!("breadcrumb" in to.meta)) {
          this.crumbs = [];
          return;
        }

        let cachedBc = localStorage.getItem("breadcrumb");
        if (cachedBc == null || cachedBc.search('fas ')) {
          cachedBc = {};
          localStorage.setItem("breadcrumb", JSON.stringify(cachedBc));
        } else {
          cachedBc = JSON.parse(cachedBc);
        }

        let crumb = [];
        if (!(this.$route.fullPath in cachedBc) || to.name === "Checkout") {
          to.meta.breadcrumb.forEach((element) => {
            let link;
            if ("route" in element) {
              link = element.route;
            } else if ("routeName" in element) {
              if (element.routeName === "Checkout" && to.name === "Checkout") {
                link = to.fullPath;
              } else {
                link = this.$router.resolve({ name: element.routeName }).fullPath;
              }
            }
            crumb.push({
              text: element.text,
              link,
            });
          });
          cachedBc[this.$route.fullPath] = crumb;
          localStorage.setItem("breadcrumb", JSON.stringify(cachedBc));
        } else {
          crumb = cachedBc[this.$route.fullPath];
        }
        this.crumbs = crumb;
      } else {
        this.updateDynamicBreadcrumb(val);
      }
    },
    getBreadcrumbDetails: function (val) {
      if (val.name) {
        this.crumbs[1].text = val.name;
      }
    },
    getDynamicBreadcrumb: function (val) {
      this.updateDynamicBreadcrumb(val)
    },
  },
};
</script>
<style scoped>
.breadcrumb_container {
  border-top: 1px solid #ebebea;
  border-bottom: 1px solid #ebebea;
  padding: 14px 0px;
}

.breadcrumb_container a {
  color: #000;
}
</style>